import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';

import { BaseUrlContext, ConfigContext, ProjectContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Input from '@ui/Input';
import MarkdownEditor from '@ui/MarkdownEditor';
import { notify, ErrorNotification } from '@ui/Notification';

import { handleCreatePost } from '../helpers';
import classes from '../style.module.scss';

const NewQuestionForm = props => {
  const { csrfToken } = props;
  const { project } = useContext(ProjectContext);
  const {
    reCaptchaSiteKey: projRecaptchaSiteKey,
    flags: { disableAnonForum },
  } = project;
  const baseUrl = useContext(BaseUrlContext);
  const { domainFull } = useContext(ConfigContext);
  const { readmeRecaptchaSiteKey } = useContext(ConfigContext);
  const recaptchaRef = useRef();
  const history = useHistory();
  const [loggedOutUser, setLoggedOutUser] = useState({
    name: '',
    email: '',
  });
  const [newTitle, setNewTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [validate, setValidate] = useState(false);
  const [createPostIsLoading, setCreatePostIsLoading] = useState(false);
  const [reCaptchaSiteKey, setRecaptchaSiteKey] = useState(projRecaptchaSiteKey);
  const [forceRecaptcha, setForceRecaptcha] = useState(false);
  const [recaptchaSize, setRecaptchaSize] = useState('invisible');
  const [spamScore, setSpamScore] = useState(null);
  const { isLoggedIn } = useUserPermissions();
  const { isClient } = useEnvInfo();
  const newUrl = `${isClient ? window.location.pathname : '/discuss-new'}`;

  const [editor, setEditor] = useState();
  const onChange = useCallback((_, e) => setEditor(e), []);

  useEffect(() => {
    if (errorMessage?.score) {
      setSpamScore(errorMessage.score);
    }

    const err = errorMessage?.errors?.title?.message || errorMessage?.message;
    return err ? notify(<ErrorNotification>{err}</ErrorNotification>) : undefined;
  }, [errorMessage]);

  useEffect(() => {
    if (forceRecaptcha) {
      recaptchaRef.current?.reset();
      setRecaptchaSiteKey(readmeRecaptchaSiteKey);
      setRecaptchaSize('compact');
    }
  }, [forceRecaptcha, readmeRecaptchaSiteKey]);

  const createPost = async () => {
    setValidate(true);
    let errors = false;
    const body = editor?.toString() || '';

    if (!newTitle || !body) {
      errors = true;
      notify(<ErrorNotification>Your post {!newTitle ? 'title' : 'body'} cannot be blank.</ErrorNotification>);
    }

    if (!isLoggedIn && (!loggedOutUser.name || !loggedOutUser.email)) {
      errors = true;
      notify(<ErrorNotification>Please fill out your name and email.</ErrorNotification>);
    }

    setCreatePostIsLoading(true);

    let recaptchaResponse;
    if (reCaptchaSiteKey && recaptchaRef) {
      try {
        recaptchaResponse = forceRecaptcha
          ? recaptchaRef.current.getValue() // this is for verifying visible recaptchas
          : await recaptchaRef.current.executeAsync(); // this is for verifying invisible recaptchas
        recaptchaRef.current?.reset();
        if (!recaptchaResponse) {
          errors = true;
          notify(<ErrorNotification>Please complete the reCaptcha verification</ErrorNotification>);
        }
      } catch (error) {
        errors = true;
        notify(<ErrorNotification>Invalid ReCaptcha tokens</ErrorNotification>);
      }
    }

    if (errors) {
      setCreatePostIsLoading(false);
      return;
    }

    // eslint-disable-next-line consistent-return
    return handleCreatePost({
      csrfToken,
      forceRecaptcha,
      loggedOutUser,
      post: {
        title: newTitle,
        body,
        spamScore,
      },
      recaptchaResponse,
      redirect: id => history.push(`/discuss/${id}`),
      setErrorMessage,
      setCreatePostIsLoading,
      setForceRecaptcha,
      url: newUrl,
    });
  };

  const onChangeInput = ({ updatedTitle }) => {
    if (errorMessage) setErrorMessage(null);
    setNewTitle(updatedTitle);
  };

  return (
    <div className={classes.DiscussNewQuestionForm}>
      <Input
        className={classes.DiscussNewQuestionForm_input}
        onChange={e => onChangeInput({ updatedTitle: e.target?.value })}
        placeholder="Your question title"
        required={validate}
        type="text"
        value={newTitle}
      />
      <Box className={`${classes.DiscussEditorWrapper} ${classes['DiscussEditorWrapper-large']}`} kind="rule">
        <MarkdownEditor
          aria-label="New Question"
          basic
          className={classes.DiscussNewQuestionForm_body}
          domainFull={domainFull}
          imageUpload={false}
          onChange={onChange}
          projectBaseUrl={baseUrl}
        />
      </Box>
      {!isLoggedIn && (
        <div className={classes['DiscussPost-newComment_inputs']}>
          <Input
            className={classes['DiscussPost-newComment_input']}
            onChange={e => setLoggedOutUser(prevState => ({ ...prevState, name: e.target?.value }))}
            placeholder="Full name"
            required={validate}
            type="text"
            value={loggedOutUser.name}
          />
          <Input
            className={classes['DiscussPost-newComment_input']}
            onChange={e => setLoggedOutUser(prevState => ({ ...prevState, email: e.target?.value }))}
            placeholder="name@email.com"
            required={validate}
            type="email"
            value={loggedOutUser.email}
          />
        </div>
      )}
      <div className={classes.DiscussNewQuestionForm_buttons}>
        <Button
          className={`${classes['Discuss-question_button']} ${classes['DiscussPost-edit_button']}`}
          disabled={(!!disableAnonForum && !isLoggedIn) || createPostIsLoading}
          onClick={createPost}
          size="sm"
        >
          Post Question
        </Button>
        <Link className="Button Button_secondary Button_secondary_outline Button_sm" target="_self" to="/discuss">
          Cancel
        </Link>
      </div>
      {!!reCaptchaSiteKey && (
        <ReCAPTCHA
          key={recaptchaSize}
          ref={recaptchaRef}
          badge="bottomleft"
          sitekey={reCaptchaSiteKey}
          size={recaptchaSize} // if we're forcing recaptcha, let's make it a manual button instead of invisible
        />
      )}
    </div>
  );
};

const DiscussNew = props => {
  const { csrfToken, errors } = props;

  const { isLoggedIn } = useUserPermissions();
  const { domainFull } = useContext(ConfigContext);
  const {
    subdomain,
    flags: { disableAnonForum },
  } = useContext(ProjectContext).project;

  useEffect(() => {
    if (disableAnonForum && !isLoggedIn) {
      window.location = `${domainFull}/to/${subdomain}?redirect=/discuss-new`;
    }
  }, [disableAnonForum, domainFull, isLoggedIn, subdomain]);

  if (disableAnonForum && !isLoggedIn) return null;
  return (
    props && (
      <div className={classes.DiscussNew}>
        <div>
          <h1 className={classes['DiscussNew-header']}>Ask a Question</h1>
          <NewQuestionForm csrfToken={csrfToken} />
        </div>
        {errors?.length > 0 &&
          errors.map(err => notify(<ErrorNotification>Error: {err.message || err}</ErrorNotification>))}
      </div>
    )
  );
};

DiscussNew.propTypes = {
  csrfToken: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.object),
};

NewQuestionForm.propTypes = {
  appId: PropTypes.string,
  csrfToken: PropTypes.string,
};

export default DiscussNew;
